import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/layout/layout";

const useStyles = makeStyles((theme) => ({
  row1: {
    paddingTop: "4em",
    backgroundColor: theme.palette.background.default,
  },
}));

const Product = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.row1}>
            <Typography variant="h1">Product</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Product;

Product.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape(
    PropTypes.exact({
      slug: PropTypes.string.isRequired,
      langKey: PropTypes.string.isRequired,
    })
  ),
};

export const query = graphql`
  query ProductQueryFr {
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
